import { template as template_48d208ba7b71495a984e568ccec25b4b } from "@ember/template-compiler";
const WelcomeHeader = template_48d208ba7b71495a984e568ccec25b4b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
