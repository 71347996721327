import { template as template_42da5d04c8be4c78a3a329c13a265e68 } from "@ember/template-compiler";
const FKControlMenuContainer = template_42da5d04c8be4c78a3a329c13a265e68(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
