import { template as template_45347c86366a4ac7a871c00c4eefa4d0 } from "@ember/template-compiler";
const SidebarSectionMessage = template_45347c86366a4ac7a871c00c4eefa4d0(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
