import { template as template_954a459bfc4f403f913181243c3a94c5 } from "@ember/template-compiler";
const FKText = template_954a459bfc4f403f913181243c3a94c5(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
